/* eslint-disable import/prefer-default-export */

// async load gtm so that it doesnt tank our performance metrics
export const onInitialClientRender = () => {
  /*
  disabling this for now, till we are able to separate google analytics from google-tag-manager

  let functionWasCalled = false;
  function addGtmScript() {
    if (functionWasCalled) {
      return;
    }
    functionWasCalled = true;

    const gtmScript = document.createElement('script');
    gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-P6HDGM2';
    gtmScript.onload = function () {
      // init GTM
      const dataLayer = window.dataLayer || [];
      dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
    };

    document.head.appendChild(gtmScript);
  }

  if (document.readyState === undefined || document.readyState === 'complete') {
    addGtmScript();
  } else {
    document.addEventListener('readystatechange', event => {
      if (event.target.readyState === 'complete') {
        addGtmScript();
      }
    });
  } */
};
