exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-deletion-confirm-js": () => import("./../../../src/pages/account-deletion/confirm.js" /* webpackChunkName: "component---src-pages-account-deletion-confirm-js" */),
  "component---src-pages-account-deletion-index-js": () => import("./../../../src/pages/account-deletion/index.js" /* webpackChunkName: "component---src-pages-account-deletion-index-js" */),
  "component---src-pages-careerdisclosures-js": () => import("./../../../src/pages/careerdisclosures.js" /* webpackChunkName: "component---src-pages-careerdisclosures-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-locations-fridge-location-fridge-id-index-js": () => import("./../../../src/pages/locations/{fridgeLocation.fridgeId}/index.js" /* webpackChunkName: "component---src-pages-locations-fridge-location-fridge-id-index-js" */),
  "component---src-pages-locations-fridge-location-fridge-id-menu-index-js": () => import("./../../../src/pages/locations/{fridgeLocation.fridgeId}/menu/index.js" /* webpackChunkName: "component---src-pages-locations-fridge-location-fridge-id-menu-index-js" */),
  "component---src-pages-locations-map-index-js": () => import("./../../../src/pages/locations-map/index.js" /* webpackChunkName: "component---src-pages-locations-map-index-js" */),
  "component---src-pages-locations-retail-location-retail-id-index-js": () => import("./../../../src/pages/locations/{retailLocation.retailId}/index.js" /* webpackChunkName: "component---src-pages-locations-retail-location-retail-id-index-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-menu-products-fridge-menu-product-pretty-name-js": () => import("./../../../src/pages/menu/products/{fridgeMenuProduct.prettyName}.js" /* webpackChunkName: "component---src-pages-menu-products-fridge-menu-product-pretty-name-js" */),
  "component---src-templates-contentful-page-js": () => import("./../../../src/templates/contentfulPage.js" /* webpackChunkName: "component---src-templates-contentful-page-js" */)
}

